
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    /**
     * Change the current block shape
     * @param input Name and id of the new shape
     */
    changeBlock(input: { name: string; id: string }) {
      this.selected = input.id;
      this.$emit("changeBlock", input.id);
    },
  },
  props: {
    images: Array,
    currentBlock: String,
  },
  mounted() {
    this.selected = this.currentBlock;
  },
})
export default class LevelEditorBlocks extends Vue {}
