
import { Options, Vue } from "vue-class-component";
import { userAPI } from "@/utils/apiCalls";
import { store } from "@/store";

@Options({
  data() {
    return {
      levelName: "",
      levelTheme: "farm",
      levelSeed: "",
    };
  },
  watch: {
    //Update theme on level theme change
    levelTheme() {
      this.updateTheme();
    },
  },
  methods: {
    //Update database
    updateDatabase(): void {
      userAPI.setUserPreferences(this.$store.getters.getPreferences);
    },
    //Update level name
    updateName(): void {
      console.log('levelname', this.levelName);
      store.commit("setLevelMakerName", this.levelName);
    },
    //Update level theme
    updateTheme() {
      store.commit("setLevelMakerTheme", this.levelTheme);
    },
    //Generate level seed
    generateSeed() {
      this.levelSeed = Math.floor(Math.random() * 999999999);
    },
    //Update level seed
    updateSeed() {
      this.levelSeed = this.levelSeed
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      store.commit("setLevelMakerSeed", this.levelSeed);
    },
  },
  computed: {
    //Get and set level constraints
    constraints: {
      get(): { name: string; langid: string; value: boolean }[] {
        return store.getters.getLevelMakerConstraints;
      },
      set(value: { name: string; langid: string; value: boolean }[]) {
        store.commit("setLevelMakerConstraints", value);
      },
    },
  },
  mounted() {
    this.levelName = store.getters.getLevelMakerName;
    this.levelSeed = store.getters.getLevelMakerSeed;
    this.levelTheme = store.getters.getLevelMakerTheme;
  },
  beforeMount() {
    this.soundEnabled = this.$store.getters.getPreferences.soundEnabled;
    this.musicEnabled = this.$store.getters.getPreferences.musicEnabled;
  },
})
export default class LevelMakerSettings extends Vue {}
