
import { Options, Vue } from "vue-class-component";
import ButtonBlock from "@/components/game/ButtonBlock.vue";
import EditorGrid from "@/components/levelMaker/EditorGrid.vue";
import LevelEditorBlocks from "@/components/levelMaker/LevelEditorBlocks.vue";
import GameView from "@/components/game/GameView.vue";
import LevelMakerSettings from "@/components/levelMaker/LevelMakerSettings.vue";
import { store } from "@/store";
import router from "@/router";
import * as PIXI from "pixi.js";
import GamePlayArea from "@/components/game/GamePlayArea.vue";
import {
  createPSDTree,
  createPSDTreeFromString,
  getUserInfo,
} from "@/utils/Functions";
import {
  levelInfo,
  startPoint,
  endPoint,
  tile,
  instruction,
  instructionList,
  levelData,
} from "@/utils/Types";
import { getWorldData } from "@/utils/apiCalls/WorldAPI";
import { createLevel, updateLevel } from "@/utils/apiCalls/LevelAPI";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  data() {
    return {
      startEnterVisible: "",
      answerMode: false,
      editorActive: true,
      blockType: "start",
      errors: [],
      errorMessage: "",
      errorVisible: "",
      succesDisplay: "",
      images: [
        { name: "Heart", id: "heart" },
        { name: "Diamond", id: "diamond" },
        { name: "Circle", id: "circle" },
        { name: "Triangle", id: "triangle" },
        { name: "Hexagon", id: "hexagon" },
        { name: "Square", id: "square" },
      ],
      wikiSubjects: [],
    };
  },
  mounted() {
    this.updateQuestionButton();
  },
  methods: {
    //Check what the used blocks are in an answer
    getUsedBlocks() {
      //Go through the json of the psd and find the types
      let allTypes = new Set();
      const json = JSON.parse(createPSDTree());
      this.checkJsonKeys(json, allTypes);
      allTypes.delete("unknownExpression");
      store.commit("setLevelMakerAnswerBlocks", allTypes);

      //Save the html to the store for later use
      this.$store.commit(
        "setLevelMakerAnswerHTML",
        document.getElementById("psdBlock")?.innerHTML.replace("gu-transit", "")
      );
    },
    /*eslint-disable */
    //Disabled the linter for this function, because the JSON needs to be an any to accept the entire answer
    //Checks all the types of operations in the json and returns these types
    checkJsonKeys(json: any, output: Set<string>) {
      for (var key of Object.keys(json)) {
        if (key === "type") {
          output.add(json[key]);
        } else if (key === "op") {
          output.add(json[key]);
        } else if (json[key] instanceof Object) {
          this.checkJsonKeys(json[key], output);
        }
      }
    },
    /*eslint-enable */
    /**
     * Change block tile
     * @param type Block tile type
     */
    changeBlock(type: string) {
      this.blockType = type;
    },
    /**
     * Use this to generate the level layout data
     * @param layout Level block tiles
     */
    createLevelData(layout: string[][]): levelData {
      console.log("layout createleveldata: ", layout);

      const tiles = this.getTiles(layout) as [
        start: startPoint[],
        end: endPoint[],
        path: tile[]
      ];
      const instructionList: instructionList = this.createInstructionsList();

      const size: { width: number; height: number } = {
        width: layout.length,
        height: layout[0].length,
      };
      const levelLayout = {
        levelInstructions: instructionList,
        size: size,
        seed: store.getters.getLevelMakerSeed,
        interactables: [],
        obstacles: [],
        entities: [],
        start: tiles[0],
        end: tiles[1],
        path: tiles[2],
        theme: store.getters.getLevelMakerTheme,
        nrOfMedals: "three",
      };
      return levelLayout;
    },
    /**
     * Set properties of the start tile, end tile and the whole path
     */
    getTiles(
      layout: string[][]
    ): [start: startPoint[], end: endPoint[], path: tile[]] {
      let start: startPoint[] = [];
      let end: endPoint[] = [];
      let path: tile[] = [];
      for (let x = 0; x < layout.length; x++) {
        for (let y = 0; y < layout[x].length; y++) {
          // Filter out the undefined blocks
          if (layout[x][y] === undefined) {
            continue;
          }

          // Split the defined block string to get different properties
          let split: string[] = layout[x][y].split(";");

          // Set start tile properties
          if (split[1] === "start") {
            start = [
              {
                id: "player_robot",
                rot: this.getStartRotation(layout, x, y),
                x: x,
                y: y,
              },
            ];
          }

          // Set end tile properties
          if (split[1] === "stop") {
            end = [{ x: x, y: y }];
          }

          // Set the path properties
          let object: tile = { x: x, y: y, type: split[0] };
          path.push(object);
        }
      }
      return [start, end, path];
    },
    /**
     * Calculates the right rotation for the character
     */
    getStartRotation(layout: string[][], x: number, y: number): number {
      // Check if x + 1 is within the bounds of the array
      if (x + 1 < layout.length && layout[x + 1][y]) return 90;
      // Check if x - 1 is within the bounds and non-negative
      if (x - 1 >= 0 && layout[x - 1][y]) return 270;
      // Check if y + 1 is within the bounds of the array
      if (y + 1 < layout[x].length && layout[x][y + 1]) return 180;
      return 0;
    },
    //Use this to generate the instructions list based on the answer and selected
    createInstructionsList(): instructionList {
      let needed: instruction[] = [];
      let extra: instruction[] = [];

      const inAnswer: string[] = store.getters.getLevelMakerAnswerBlocks;
      const extraSelected: string[] = store.getters.getLevelMakerExtraBlocks;
      const remainingBlocks: string[] =
        store.getters.getLevelMakerRemainingBlocks;

      //Add the answer and selected blocks to the needed field
      inAnswer.forEach((block) => {
        needed.push({ name: block });
      });
      extraSelected.forEach((block) => {
        needed.push({ name: block });
      });

      //Add the rest of the blocks
      remainingBlocks.forEach((block) => {
        extra.push({ name: block });
      });

      return { needed: needed, extra: extra };
    },
    //Set level settings
    setSettings() {
      store.state.levelSettings = {
        levelId: 1,
        allowPsd: true,
        allowWritten: true,
      };
    },
    /**
     * Called when saving the leveldata.
     * Use this to convert the grid to level data
     * @param onlySave If the level is only saved
     */
    saveGame(onlySave: boolean) {
      console.log("SAVELEVEL");
      let checkOutput: string[][] | null = this.checkErrors();
      if (checkOutput === null) {
        return;
      } else {
        //Check if there's a level to save to
        if (store.getters.getLevelMakerId < 0) {
          let l = createLevel("robbie", store.getters.getLevelMakerName);
          store.commit("setLevelMaker", l);
        }

        //No errors, so save
        const levelLayoutData: levelData = this.createLevelData(checkOutput);
        console.log('levelMakerAnswer', store.getters.getLevelMakerAnswerHTML);
        const answer = createPSDTreeFromString(
          store.getters.getLevelMakerAnswerHTML
        );
        console.log('answer', answer);
        const profile = this.$store.getters.getUserInfo;
        const now = new Date();

        const output: levelInfo = {
          levelId: store.getters.getLevelMakerId,
          userId: profile.userId,
          name: store.getters.getLevelMakerName,
          levelData: levelLayoutData,
          preview: "",
          solution: answer,
          timestamp: now.toISOString(),
          published: false,
          world: "robbie",
          seed: store.getters.getLevelMakerSeed,
        };

        this.$store.commit("setLevelInfo", output);
        this.editorActive = false;

        //Always run this after setting the levelInfo, because we otherwise lose data
        //We need time to render the world before we can create a screenshot
        setTimeout(() => this.getPreview(onlySave), 500);

        this.setSettings();
      }
    },
    //Get random integer
    getRandomInt(max: number) {
      return Math.floor(Math.random() * max);
    },
    /**
     * Called when updating the preview.
     * Use this to create a base64 preview and saving it to the store
     */
    getPreview(onlySave: boolean) {
      this.$store.commit("setLevelPreview", this.getBase64Preview());
      this.editorActive = true;
      updateLevel(this.$store.state.levelInfo);

      if (onlySave) {
        this.succesDisplay = "overlayVisible";
      } else {
        this.goToGame();
      }
    },
    /**
     * Called when wanting a base64 string
     * Use this to convert a pixiJs application to base64
     */
    getBase64Preview(): string {
      const app = this.$refs.gameRender.pixi as PIXI.Application;
      let res: string = app.renderer.plugins.extract.base64(app.stage);
      res = res.substring(22);
      return res;
    },
    async goToGame() {
      await getWorldData(store.state.levelInfo.world).then(() => {
        this.$store.commit("turnPreviewOn");
        router.push("/game");
      });
    },
    /**
     * Called when checking the grid.
     * Use this to check if the grid has all the requirements
     */
    checkErrors() {
      let gridTiles = this.$refs.grid.gridTiles;
      let startCount = 0;
      let stopCount = 0;
      let zeroPoint = { x: 999, y: 999 };
      let maxPoint = { x: -1, y: -1 };
      //Making the boundries and checking the amount of start and stop
      for (let i = 0; i < gridTiles.length; i++) {
        if (gridTiles[i].x < zeroPoint.x) {
          zeroPoint.x = gridTiles[i].x;
        }
        if (gridTiles[i].y < zeroPoint.y) {
          zeroPoint.y = gridTiles[i].y;
        }
        if (gridTiles[i].x > maxPoint.x) {
          maxPoint.x = gridTiles[i].x;
        }
        if (gridTiles[i].y > maxPoint.y) {
          maxPoint.y = gridTiles[i].y;
        }

        const startStopCheck = gridTiles[i].object.split(";")[1];
        if (startStopCheck === "start") {
          startCount++;
        }
        if (startStopCheck === "stop") {
          stopCount++;
        }
      }

      //Checks for the start end finish blocks
      if (startCount > 1) {
        this.errors.push(this.$t("levelMaker.errors.muchStart"));
      }
      if (stopCount > 1) {
        this.errors.push(this.$t("levelMaker.errors.muchStop"));
      }
      if (startCount == 0) {
        this.errors.push(this.$t("levelMaker.errors.startNeeded"));
      }
      if (stopCount == 0) {
        this.errors.push(this.$t("levelMaker.errors.stopNeeded"));
      }

      //Check if we have a name and answer
      if (this.$store.getters.getLevelMakerName === "") {
        this.errors.push(this.$t("levelMaker.errors.nameNeeded"));
      }
      if (this.$store.getters.getLevelMakerAnswerHTML === "") {
        this.errors.push(this.$t("levelMaker.errors.answerNeeded"));
      }

      //No errors
      if (this.errors.length == 0) {
        //Create a array of the grid
        let arr: string[][] = Array.from(
          Array(Math.abs(maxPoint.x - zeroPoint.x) + 1),
          () => new Array(Math.abs(maxPoint.y - zeroPoint.y) + 1)
        );
        let startIndex;
        let stopIndex;

        //Fill the array with all the values of the world
        for (let i = 0; i < gridTiles.length; i++) {
          arr[gridTiles[i].x - zeroPoint.x][gridTiles[i].y - zeroPoint.y] =
            gridTiles[i].object;

          const startStopCheck = gridTiles[i].object.split(";")[1];
          if (startStopCheck === "start") {
            startIndex = {
              x: gridTiles[i].x - zeroPoint.x,
              y: gridTiles[i].y - zeroPoint.y,
            };
          }
          if (startStopCheck === "stop") {
            stopIndex = {
              x: gridTiles[i].x - zeroPoint.x,
              y: gridTiles[i].y - zeroPoint.y,
            };
          }
        }
        //Check if there is a path from start to finish
        if (
          this.dfs(
            startIndex,
            stopIndex,
            {
              start: { x: 0, y: 0 },
              end: { x: maxPoint.x - zeroPoint.x, y: maxPoint.y - zeroPoint.y },
            },
            arr
          )
        ) {
          //Everything is okay
          return arr;
        } else {
          this.errors.push(this.$t("levelMaker.errors.pathNeeded"));
        }
      }

      //Show errors
      if (this.errors.length >= 1) {
        this.errorVisible = "overlayVisible";
        return null;
      }
    },
    /**
     * Called when checking if there is a route between two points
     * Use this to check if there is a path between your start and finish block
     * @param source Route source
     * @param target Route target
     * @param boundry Route boundries
     * @param grid Route grid
     */
    dfs(
      source: { x: number; y: number },
      target: { x: number; y: number },
      boundry: {
        start: { x: number; y: number };
        end: { x: number; y: number };
      },
      grid: string[][]
    ) {
      const queue = [source];
      const visited = new Set();

      //Loop until we have no more nodes in the queue
      while (queue.length > 0) {
        const currentNode = queue.pop();

        if (currentNode == undefined) {
          return false;
        }

        //Found target
        if (currentNode.x === target.x && currentNode.y === target.y) {
          return true;
        }

        //Create all the possible new nodes
        var next: { x: number; y: number }[] = [];
        if (currentNode != undefined) {
          if (currentNode.x + 1 <= boundry.end.x) {
            if (grid[currentNode.x + 1][currentNode.y] != undefined) {
              next.push({ x: currentNode.x + 1, y: currentNode.y });
            }
          }
          if (currentNode.x - 1 >= boundry.start.x) {
            if (grid[currentNode.x - 1][currentNode.y] != undefined) {
              next.push({ x: currentNode.x - 1, y: currentNode.y });
            }
          }
          if (currentNode.y + 1 <= boundry.end.y) {
            if (grid[currentNode.x][currentNode.y + 1] != undefined) {
              next.push({ x: currentNode.x, y: currentNode.y + 1 });
            }
          }
          if (currentNode.y - 1 >= boundry.start.y) {
            if (grid[currentNode.x][currentNode.y - 1] != undefined) {
              next.push({ x: currentNode.x, y: currentNode.y - 1 });
            }
          }
        }

        //Push new nodes to the queue if it is not already in the visited set
        for (let i = 0; i < next.length; i++) {
          if (visited.has(next[i].x + ";" + next[i].y)) {
            continue;
          }

          if (!queue.includes(next[i])) {
            queue.push(next[i]);
          }
        }

        //Add to visited set
        visited.add(currentNode.x + ";" + currentNode.y);
      }

      //No route is found
      return false;
    },
    //Update question button wiki items
    updateQuestionButton() {
      this.wikiSubjects.push({
        wikiButton: { name: this.$t("wiki.levMaker"), item: "levMakerPage" },
      });
    },
    getUserInfo,
  },
  components: {
    ButtonBlock,
    EditorGrid,
    GameView,
    LevelMakerSettings,
    LevelEditorBlocks,
    GamePlayArea,
    QuestionButton,
  },
})
export default class LevelMaker extends Vue {}
