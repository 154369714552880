
import { Options, Vue } from "vue-class-component";
import * as PIXI from "pixi.js";
import { store } from "@/store";
import { PixiJSGrid } from "@/utils/PixijsGrid";
import { gridCoordinates, levelData } from "@/utils/Types";

@Options({
  props: {
    blockType: String,
    images: Array,
    editMode: Boolean,
  },
  data() {
    return {
      pixi: null as PIXI.Application | null,
      canvas: HTMLCanvasElement,
      app: PIXI.Application,
      grid: PixiJSGrid,
      width: 0,
      height: 0,
      zoom: 4,
      gridSet: false,
      mouseDown: false,
      canDrag: false,
      currentValue: "",
      selectedOption: "",
      startSelected: null,
      buttonPressed: -1,
      offsetX: 0,
      offsetY: 0,
      placedX: -1,
      placedY: -1,
      startX: 0,
      startY: 0,
      previousDragX: 0,
      previousDragY: 0,
      startEnterVisible: "",
    };
  },
  mounted() {
    this.createGridFromDatabase();
    this.initGrid();
    window.addEventListener("resize", () => {
      this.loadGrid();
    });
    this.selectedOption = this.images[0].id;
  },
  computed: {
    //Determine size of grid cells
    cellSize() {
      return Math.max(this.width, this.height) / 50;
    },
    //Outside width
    outsideX() {
      return (this.cellSize * 50 * this.zoom - this.width) / 2;
    },
    //Outside height
    outsideY() {
      return (this.cellSize * 50 * this.zoom - this.height) / 2;
    },
    //Translate x coördinates
    translateX() {
      this.capTranslationX();
      return -this.outsideX + this.offsetX;
    },
    //Translate y coördinates
    translateY() {
      this.capTranslationY();
      return -this.outsideY + this.offsetY;
    },
    //Get and set grid tiles
    gridTiles: {
      get(): gridCoordinates[] {
        return store.getters.getLevelMakerGrid;
      },
      set(value: gridCoordinates[]) {
        store.commit("setLevelMakerGrid", value);
      },
    },
  },
  methods: {
    //Create grid as stored in database
    createGridFromDatabase(): void {
      const layout: levelData = this.$store.getters.getLevelMakerlayout;
      const newLevel = this.$store.getters.getLevelMakerNewLevel;
      console.log('canvas', this.canvas);
      if (layout === null && newLevel) {
        this.clearGrid();
        return;
      }

      console.log('layout', layout);
      console.log('newlevel', newLevel);
      //Layout is not null, so create the grid
      let gridTiles: gridCoordinates[] = [];
      //Calculate offset to put level in center
      let offX = 25 - Math.floor(layout.size.width / 2);
      let offY = 25 - Math.floor(layout.size.height / 2);
      if (offX + layout.size.width > 50)
        offX = Math.max(50 - offX + layout.size.width, 0);
      if (offY + layout.size.height > 50)
        offY = Math.max(50 - offY + layout.size.height, 0);
      layout.path.forEach((tile) => {
        let type: string = tile.type;
        if (tile.x === layout.start[0].x && tile.y === layout.start[0].y) {
          //Found start
          type += ";start";
        }
        if (tile.x === layout.end[0].x && tile.y === layout.end[0].y) {
          console.log('end', tile);
          //Found end
          type += ";stop";
        }
        gridTiles.push({ x: tile.x + offX, y: tile.y + offY, object: type });
      });
      console.log("filling grid");
      this.$store.commit("setLevelMakerNewLevel", false);
      this.$store.commit("setLevelMakerGrid", gridTiles);
    },

    clearGrid(): void {
      this.gridTiles = [];
      this.gridSet = false;
    },  
    //Initialize editor grid
    initGrid() {
      this.canvas = document.getElementById("editorGrid") as HTMLCanvasElement;
      console.log('init grid', this.canvas);


      this.app = new PIXI.Application({
        backgroundAlpha: 0,
        view: this.canvas,
        antialias: true,
      });

      //Update grid position on drag
      this.canvas.addEventListener("mousemove", (event: MouseEvent) => {
        if (!this.editMode) {
          return;
        }

        if (this.mouseDown && this.canDrag) {
          this.offsetX += event.offsetX - this.previousDragX;
          this.offsetY += event.offsetY - this.previousDragY;
          this.previousDragX = event.offsetX;
          this.previousDragY = event.offsetY;

          this.loadGrid();
        } else if (this.mouseDown && !this.canDrag) {
          const tileSize = this.cellSize * this.zoom;
          let x = Math.floor(
            (this.outsideX - this.offsetX + event.offsetX) / tileSize
          );
          let y = Math.floor(
            (this.outsideY - this.offsetY + event.offsetY) / tileSize
          );

          if (this.placedX != x || this.placedY != y) {
            this.placeItem(event);
          }
        }
      });

      this.canvas.addEventListener("wheel", (event: WheelEvent) => {
        if (this.editMode) {
          if (event.deltaY < 0) {
            this.zoomIn();
          } else if (event.deltaY > 0) {
            this.zoomOut();
          }
        }
      });

      //Stop grid move on mouseup after drag
      window.addEventListener("mouseup", () => {
        if (!this.editMode) {
          return;
        }
        //Reset middle mouse
        if (this.buttonPressed == 1) {
          this.canDrag = false;
          let element = document.getElementById("editorGrid") as HTMLElement;
          element.style.cursor = "default";
        }

        this.buttonPressed = -1;
        this.mouseDown = false;
        if (this.canDrag) {
          let element = document.getElementById("editorGrid") as HTMLElement;
          element.style.cursor = "grab";
        }
      });

      //Place tile on mouseup
      this.canvas.addEventListener("mousedown", (event: MouseEvent) => {
        if (!this.editMode) {
          return;
        }
        this.buttonPressed = event.button;

        //Change mode to dragging with middle mouse
        if (this.buttonPressed == 1) {
          this.canDrag = true;
          let element = document.getElementById("editorGrid") as HTMLElement;
          element.style.cursor = "grabbing";
        }

        if (!this.canDrag) {
          this.mouseDown = true;
          this.placeItem(event);
        } else {
          this.mouseDown = true;
          this.startX = event.offsetX;
          this.startY = event.offsetY;
          this.previousDragX = event.offsetX;
          this.previousDragY = event.offsetY;
          let element = document.getElementById("editorGrid") as HTMLElement;
          element.style.cursor = "grabbing";
        }
      });

      this.loadGrid();
    },
    //Load the editor grid
    loadGrid() {
      this.updateSize();

      if (!this.gridSet) {
        this.grid = new PixiJSGrid(this.width, this.height, this.cellSize);
        this.app.stage.addChild(this.grid);
        this.gridSet = true;
      }

      this.grid.drawGrid(
        this.width,
        this.height,
        this.cellSize,
        this.gridTiles
      );
      this.app.stage.transform.setFromMatrix(
        new PIXI.Matrix(
          this.zoom,
          0,
          0,
          this.zoom,
          this.translateX,
          this.translateY
        )
      );
    },
    //Update grid size for responsiveness and full block coverage
    updateSize() {
      this.width = this.canvas.getBoundingClientRect().width;
      this.height = this.canvas.getBoundingClientRect().height;
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      this.app.screen.width = this.width;
      this.app.screen.height = this.height;
    },
    //Zoom editor grid out
    zoomOut() {
      if (this.zoom > 1) {
        this.zoom -= 0.5;
        this.loadGrid();
      }
    },
    //Zoom editor grid in
    zoomIn() {
      if (this.zoom < 7) {
        this.zoom += 0.5;
        this.loadGrid();
      }
    },
    /**
     * Place tile in editor grid
     * @param event Mouse event that places the tile
     */
    placeItem(event: MouseEvent) {
      const tileSize = this.cellSize * this.zoom;
      let x = Math.floor(
        (this.outsideX - this.offsetX + event.offsetX) / tileSize
      );
      let y = Math.floor(
        (this.outsideY - this.offsetY + event.offsetY) / tileSize
      );
      this.placedX = x;
      this.placedY = y;
      let value: gridCoordinates = {
        x: x,
        y: y,
        object: this.blockType,
      };

      //Check if we pressed the start block
      if (this.blockType === "start" && this.buttonPressed === 0) {
        this.currentValue = value;
        this.startSelected = true;
        this.startEnterVisible = "overlayVisible";
        return;
      }
      //Check if we pressed the stop block
      if (this.blockType === "stop" && this.buttonPressed === 0) {
        this.currentValue = value;
        this.startSelected = false;
        this.placeEnd();
        //this.startEnterVisible = "overlayVisible";
        return;
      }

      if (
        this.gridTiles.filter((e: gridCoordinates) => e.x === x && e.y === y)
          .length > 0
      ) {
        var index = 0;

        this.gridTiles.find(function (item: gridCoordinates, i: number) {
          if (item.x === x && item.y === y) {
            index = i;
            return;
          }
        });

        let check = this.gridTiles[index];

        if (this.buttonPressed == 2) {
          if (index > -1) {
            this.gridTiles.splice(index, 1);
          }
          this.loadGrid();
          return;
        }

        if (check.object != this.blockType) {
          if (index > -1) {
            this.gridTiles.splice(index, 1);
          }
          this.gridTiles.push(value);
        }
      } else {
        if (this.buttonPressed == 0) {
          this.gridTiles.push(value);
        }
      }

      this.loadGrid();
    },
    //Place end tile
    placeEnd() {
      let value: gridCoordinates = this.currentValue;
      value.object = this.startSelected
        ? this.selectedOption + ";" + "start"
        :"stop" + ";" + "stop";

      console.log('placeend value', value);
      if (
        this.gridTiles.filter(
          (e: gridCoordinates) => e.x === value.x && e.y === value.y
        ).length > 0
      ) {
        var index = 0;

        this.gridTiles.find(function (item: gridCoordinates, i: number) {
          if (item.x === value.x && item.y === value.y) {
            index = i;
            return;
          }
        });

        let check = this.gridTiles[index];

        if (this.buttonPressed == 2) {
          if (index > -1) {
            this.gridTiles.splice(index, 1);
          }
          this.loadGrid();
          return;
        }
        if (check.object != this.blockType) {
          if (index > -1) {
            this.gridTiles.splice(index, 1);
          }
          this.gridTiles.push(value);
        }
      } else {
        this.gridTiles.push(value);
      }

      this.loadGrid();
    },
    //Change drag mode on or off
    changeDragMode() {
      if (this.canDrag) {
        this.canDrag = false;
        let element = document.getElementById("editorGrid") as HTMLElement;
        element.style.cursor = "default";
      } else {
        this.canDrag = true;
        let element = document.getElementById("editorGrid") as HTMLElement;
        element.style.cursor = "grab";
      }
    },
    //Cap x translation to prevent empty space next to the grid
    capTranslationX() {
      if (this.offsetX > this.outsideX) {
        this.offsetX = this.outsideX;
      } else if (this.startX - this.offsetX > this.outsideX) {
        this.offsetX = -this.outsideX;
      }
    },
    //Cap y translation to prevent empty space next to the grid
    capTranslationY() {
      if (this.offsetY > this.outsideY) {
        this.offsetY = this.outsideY;
      } else if (this.startY - this.offsetY > this.outsideY) {
        this.offsetY = -this.outsideY;
      }
    },
  },
})
export default class EditorGrid extends Vue {}
